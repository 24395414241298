import { motion } from "framer-motion";
const TermsAndConditions = () => {
  return (
    <div className="w-full md:py-[8rem] py-12 mb-12 lg:mb-0 text-center lg:text-left container flex flex-col items-center lg:flex-row ">
      <div className="w-full">
        <motion.div
          initial={{ y: "-100%", opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          className="w-full"
        >
          <h1 className="md:text-5xl text-xl text-center leading-7 font-semibold md:leading-9 text-gray-900  w-full font-inter">
            Terms and Conditions <br />
          </h1>
          <div className="w-full flex items-center justify-center">
            <p className="md:mt-6 mt-6 md:text-lg text-xs  font-normal   text-center text-gray-500 lg:max-w-[80rem]  md:leading-7 leading-6 font-inter">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
