import React, { useEffect } from "react";
import { motion, useAnimation, useInView } from "framer-motion"; // Use only Framer Motion hooks
import Button from "./shared-components/Button";
import { ArrowRightIcon, WatchIcon } from "../assets/svg";
import heroMap from "../assets/heroMap.png";
import heroMac from "../assets/heroMac.png";
import heroUser from "../assets/heroUser.png";
import heroTodo from "../assets/heroTodo.svg";
import greenArrowDown from "../assets/greenArrowDown.svg";
import HereBranding from "./HereBranding";

const HeroSection = () => {
  const controls = useAnimation();
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [controls, isInView]);

  const leftVariants = {
    hidden: { opacity: 0, x: -600 }, // Start farther left
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 1.5, ease: "easeInOut" }, // Slower and smoother
    },
  };

  const rightVariants = {
    hidden: { opacity: 0, x: 600 }, // Start farther right
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 1.5, ease: "easeInOut" }, // Slower and smoother
    },
  };

  const bottomVariants = {
    hidden: { opacity: 0, y: 400 }, // Start farther down
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1.5, ease: "easeInOut" }, // Slower and smoother
    },
  };

  // New variant for top-to-bottom effect (for heading, description, buttons)
  const topVariants = {
    hidden: { opacity: 0, y: -50 }, // Start slightly above
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1.5, ease: "easeInOut" }, // Slower and smoother
    },
  };

  return (
    <div
      className="lg:px-14 px-6"
      style={{ background: "linear-gradient(180deg, #FBFBFD 0%, #F9FBFE 100%" }}
      ref={ref} // Attach ref here for inView hook
    >
      <div className="flex flex-col items-center lg:pt-32 pt-12">
        {/* Group Heading, Description, and Buttons in One motion.div */}
        <motion.div
          initial="hidden"
          animate={controls} // Animation controlled by Framer Motion controls
          variants={topVariants}
          className="flex flex-col items-center"
        >
          <h1 className="md:text-5xl text-xl leading-7 font-semibold md:leading-9 text-gray-900 max-w-[58rem] w-full text-center font-inter">
            Streamline your workflow and communication with{" "}
            <span className="text-green-500">Emerald</span>
          </h1>
          <p className="md:mt-8 mt-4 ml-auto mr-auto md:text-lg text-xs font-normal text-gray-800 text-center max-w-[35rem] w-full md:leading-7 leading-6 font-inter">
            Transform manual tasks into automated efficiency. Emerald Data
            Solutions is your ultimate CMMS
          </p>

          {/* Buttons */}
          <div className="flex flex-wrap gap-3 lg:mt-8 mt-4 justify-center">
            <a href="/pricing">
              <Button
                variant="default"
                iconPosition="end"
                icon={<ArrowRightIcon />}
                onClick={() => {
                  window.open("https://app.emeralddata.io/");
                }}
                // className="h-[4.5rem]"
              >
                <span>Get Started</span>
              </Button>
            </a>
            <a
              href="https://www.youtube.com/@EmeraldDataSolutions"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                variant="transparent"
                iconPosition="start"
                icon={<WatchIcon />}
              >
                Watch Video
              </Button>
            </a>
          </div>
        </motion.div>

        {/* Images Animation */}
        <div className="flex gap-[18px] items-end lg:flex-nowrap flex-wrap mt-12 lg:mt-0 justify-center">
          {/* First two images come from the extreme left */}
          <motion.div
            className="grid lg:gap-[18px] gap-4 md:grid-cols-2 grid-cols-2 items-end"
            initial="hidden"
            animate={controls} // Animate with Framer Motion controls
            variants={leftVariants}
          >
            <div
              style={{ boxShadow: "0px 4px 40px 0px rgba(15, 25, 82, 0.08)" }}
              className="rounded-xl flex"
            >
              <img src={heroMap} alt="hero-map" className="w-[17rem]" />
            </div>
            <div
              style={{ boxShadow: "0px 4px 40px 0px rgba(15, 25, 82, 0.08)" }}
              className="rounded-xl"
            >
              <img src={heroMac} alt="hero-mac" className="w-[17rem]" />
            </div>
          </motion.div>

          {/* The stats section comes from the bottom */}
          <motion.div
            className="lg:px-8 lg:py-10 p-4 bg-green-400 rounded-xl text-white"
            initial="hidden"
            animate={controls} // Animate with Framer Motion controls
            variants={bottomVariants}
          >
            <div className="flex justify-between ">
              <p className="lg:text-5xl text-3xl lg:font-semibold lg:leading-9">
                25%
              </p>
              <img src={greenArrowDown} alt="greenArrowDown" />
            </div>
            <h2 className="text-2xl leading-7 font-semibold mt-3 max-w-[11rem] w-full">
              Reduction in Costs
            </h2>
            <p className="lg:text-sm text-xs leading-5 mt-2 md:max-w-[12rem] w-full font-normal">
              Organizations can reduce maintenance costs by optimizing inventory
              management and preventative maintenance schedules.
            </p>
          </motion.div>

          {/* Last two images come from the extreme right */}
          <motion.div
            className="grid lg:gap-[18px] gap-4 md:grid-cols-2 grid-cols-2 lg:items-end items-start"
            initial="hidden"
            animate={controls} // Animate with Framer Motion controls
            variants={rightVariants}
          >
            <div
              style={{ boxShadow: "0px 4px 40px 0px rgba(15, 25, 82, 0.08)" }}
              className="rounded-xl"
            >
              <img src={heroUser} alt="hero-user" className="w-[17rem]" />
            </div>
            <div
              style={{ boxShadow: "0px 4px 40px 0px rgba(15, 25, 82, 0.08)" }}
              className="rounded-xl"
            >
              <img src={heroTodo} alt="hero-todo" />
            </div>
          </motion.div>
        </div>

        {/* Brand Logos Moving from Left to Right in a Loop */}
        <HereBranding />
      </div>
    </div>
  );
};

export default HeroSection;
