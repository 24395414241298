import React from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import siteLogo from "../../../../assets/siteLogo.svg";
import Button from "../../Button";
import { ArrowRightIcon } from "../../../../assets/svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const menuItems = [
  { id: 1, title: "Solutions", target: "solutions" },
  { id: 2, title: "Features", target: "features" },
  { id: 3, title: "Industries", target: "industries" },
  { id: 4, title: "Why EDS", target: "eds" },
  { id: 5, title: "Pricing", href: "/pricing" }, // External page
];
const scrollToSection = (id: any) => {
  const section = document.getElementById(id);
  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
  }
};

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Hook for navigation
  const handleScroll = (id: any) => {
    scrollToSection(id);
  };
  const handleScrollNavigation = (target: string) => {
    if (location.pathname !== "/") {
      navigate("/", { replace: true }); // Navigate to home first
      setTimeout(() => {
        document
          .getElementById(target)
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 100); // Delay to ensure page loads before scrolling
    }
  };
  return (
    <Disclosure
      as="nav"
      className="bg-white border-b border-gray-50 sticky top-0  z-10 "
    >
      <div className="container  lg:py-3 py-5">
        <div className="flex  justify-between">
          <div className="flex gap-[0.58rem] items-center">
            <a href="/">
              <img src={siteLogo} alt="site-logo" />
            </a>
          </div>
          <div className="flex">
            <div className="hidden sm:gap-2 sm:flex ">
              {menuItems.map((item: any) =>
                item.href ? (
                  <Link
                    to={item.href}
                    key={item.id}
                    className="inline-flex items-center p-3 text-sm font-normal font-inter text-gray-600 border-b-2 border-transparent hover:border-green-500 transition-all duration-300"
                  >
                    {item.title}
                  </Link>
                ) : (
                  <ScrollLink
                    key={item.id}
                    to={item.target ?? ""}
                    smooth
                    duration={500}
                    offset={-70} // Adjust for sticky header
                    className="inline-flex items-center p-3 text-sm font-normal font-inter text-gray-600 border-b-2 border-transparent hover:border-green-500 transition-all duration-300 cursor-pointer"
                    onClick={() => handleScrollNavigation(item.target)}
                  >
                    {item.title}
                  </ScrollLink>
                )
              )}
            </div>
          </div>

          <div className="hidden sm:ml-6 sm:flex sm:items-center gap-3">
            <a href="https://app.emeralddata.io/login">
              <Button
                variant="default"
                iconPosition="end"
                bg="black"
                className="hover:!bg-green-500 !bg-transparent !text-[#4F5356] !text-[14px] !font-medium border border-[#C9D1D6] hover:!text-white"
                // icon={<ArrowRightIcon />}
              >
                <span>Login</span>
              </Button>
            </a>
            {location.pathname !== "/pricing" && (
              <a href="https://app.emeralddata.io/">
                <Button
                  variant="default"
                  iconPosition="end"
                  bg="black"
                  className="hover:bg-green-500"
                  icon={<ArrowRightIcon />}
                >
                  <span>Get Started</span>
                </Button>
              </a>
            )}
          </div>

          <div className="-mr-2 flex items-center sm:hidden">
            {/* Mobile menu button */}
            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none bg-white ">
              <span className="absolute -inset-0.5" />
              <span className="sr-only font-inter">Open main menu</span>
              <Bars3Icon
                aria-hidden="true"
                className="block h-6 w-6 group-data-[open]:hidden"
              />
              <XMarkIcon
                aria-hidden="true"
                className="hidden h-6 w-6 group-data-[open]:block"
              />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel className="sm:hidden">
        <div className="space-y-1 pb-3 pt-2">
          {menuItems.map((item: any) => (
            <DisclosureButton
              key={item.id}
              as="a"
              href={item.href}
              className="block border-l-4 border-transparent py-2 pl-3 pr-4  font-inter text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
            >
              {item.title}
            </DisclosureButton>
          ))}
        </div>
      </DisclosurePanel>
    </Disclosure>
  );
};

export default Header;
