import React, { useEffect, useState } from "react";
import siteManagement from "../assets/siteManagement.png";
import assetManagement from "../assets/assetManagement.png";
import vendorManagement from "../assets/vendorManagement.png";
import activityOrder from "../assets/activityOrder.png";
import purchaseOrder from "../assets/purchaseOrder.png";
import reportAnalytics from "../assets/vendorManagement.png";
import { motion } from "framer-motion";

interface Tab {
  name: TabName;
  href: string;
  current: boolean;
  id?: string;
}

type TabName =
  | "Site Management"
  | "Asset Management"
  | "Vendor Management"
  | "Work Orders"
  | "Purchase Orders"
  | "Reports & Analytics";

const FeaturesSection: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<TabName>("Site Management");
  const [hashValue, setHashValue] = useState<string>("");
  console.log("🚀 ~ hashValue:", hashValue);

  const tabs: Tab[] = [
    {
      name: "Site Management",
      href: "#site",
      current: currentTab === "Site Management",
      id: "site",
    },
    {
      name: "Asset Management",
      href: "#asset",
      current: currentTab === "Asset Management",
      id: "asset",
    },
    {
      name: "Vendor Management",
      href: "#vendor",
      current: currentTab === "Vendor Management",
      id: "vendor",
    },
    {
      name: "Work Orders",
      href: "#work",
      current: currentTab === "Work Orders",
      id: "work",
    },
    {
      name: "Purchase Orders",
      href: "#purchase",
      current: currentTab === "Purchase Orders",
      id: "purchase",
    },
    {
      name: "Reports & Analytics",
      href: "#reporting",
      current: currentTab === "Reports & Analytics",
      id: "reporting",
    },
  ];

  function classNames(...classes: string[]): string {
    return classes.filter(Boolean).join(" ");
  }

  const content: { [key in TabName]: React.ReactNode } = {
    "Site Management": (
      <div className="flex md:justify-between lg:flex-nowrap flex-wrap items-center md:mt-14">
        <div className="">
          <h3 className="lg:text-3xl text-lg font-medium text-gray-900 font-inter">
            Site Management
          </h3>
          <ul className="lg:mt-6 mt-4 max-w-[29rem] w-full list-disc pl-4 lg:pl-4 ">
            <li className="leading-6 text-base text-gray-800 font-inter">
              Efficiently manage site-specific costs and review total
              organizational expenses
            </li>
            <li className="leading-6 text-base text-gray-800 font-inter">
              Centralize your data, streamline operations, and ensure
              consistency in facility standards
            </li>
            <li className="leading-6 text-base text-gray-800 font-inter">
              Coordinate maintenance activities across all your locations
              whether it is a network of campuses, factories, or retail
              storefronts
            </li>
          </ul>
        </div>
        <div className="lg:mt-0  mt-12">
          <img src={siteManagement} alt="siteMangment" className="w-[39rem]" />
        </div>
      </div>
    ),
    "Asset Management": (
      <div
        className="flex md:justify-between lg:flex-nowrap flex-wrap items-center md:mt-14"
        id="assetManagement"
      >
        <div className="">
          <h3 className="lg:text-3xl text-lg font-medium text-gray-900 font-inter">
            Asset Management
          </h3>
          <ul className="lg:mt-6 mt-4 max-w-[29rem] w-full list-disc pl-4 lg:pl-4">
            <li className="leading-6 text-base text-gray-800 font-inter">
              Fingertip access to every detail of your assets through QR code
              scanning or simply using our search tool
            </li>
            <li className="leading-6 text-base text-gray-800 font-inter">
              Apply lifecycle rules, document warranty parameters, and ensure
              compliance with regulatory requirements
            </li>
            <li className="leading-6 text-base text-gray-800 font-inter">
              Review service/maintenance history for each asset
            </li>
          </ul>
        </div>
        <div className="lg:mt-0  mt-12">
          <img src={assetManagement} alt="siteMangment" className="w-[39rem]" />
        </div>
      </div>
    ),
    "Vendor Management": (
      <div className="flex md:justify-between lg:flex-nowrap flex-wrap items-center md:mt-14">
        <div className="">
          <h3 className="lg:text-3xl text-lg font-medium text-gray-900 font-inter">
            Vendor Management
          </h3>
          <ul className="lg:mt-6 mt-4 max-w-[29rem] w-full list-disc pl-4 lg:pl-4 font-inter">
            <li className="leading-6 text-base text-gray-800 ">
              View vendor details in an organized and intuitive module for cross
              site access
            </li>
            <li className="leading-6 text-base text-gray-800 font-inter">
              Review individual vendor contact details, pricing,
              products/services offered, and contract history
            </li>
            <li className="leading-6 text-base text-gray-800 font-inter">
              Search vendor history to easily repurchase items
            </li>
          </ul>
        </div>
        <div className="lg:mt-0  mt-12">
          <img
            src={vendorManagement}
            alt="siteMangment"
            className="w-[39rem]"
          />
        </div>
      </div>
    ),
    "Work Orders": (
      <div className="flex md:justify-between lg:flex-nowrap flex-wrap items-center md:mt-14">
        <div className="">
          <h3 className="lg:text-3xl text-lg font-medium text-gray-900 font-inter">
            Work Orders
          </h3>
          <ul className="lg:mt-6 mt-4 max-w-[29rem] w-full list-disc pl-4 lg:pl-4">
            <li className="leading-6 text-base text-gray-800 font-inter">
              Create work orders with detailed descriptions, priority levels,
              and due dates
            </li>
            <li className="leading-6 text-base text-gray-800 font-inter">
              Assign work orders to technicians, sites, assets, or all of the
              above
            </li>
            <li className="leading-6 text-base text-gray-800 font-inter">
              Review your team's current workload in an calendar or task board
              view to efficiently manage resources
            </li>
          </ul>
        </div>
        <div className="lg:mt-0  mt-12">
          <img src={activityOrder} alt="activityOrder" className="w-[39rem]" />
        </div>
      </div>
    ),
    "Purchase Orders": (
      <div className="flex md:justify-between lg:flex-nowrap flex-wrap items-center md:mt-14">
        <div className="">
          <h3 className="lg:text-3xl text-lg font-medium text-gray-900 font-inter">
            Purchase Orders
          </h3>
          <ul className="lg:mt-6 mt-4 max-w-[29rem] w-full list-disc pl-4 lg:pl-4">
            <li className="leading-6 text-base text-gray-800 font-inter">
              Build purchase orders with existing templates including detailed
              item descriptions, quantities, and pricing
            </li>
            <li className="leading-6 text-base text-gray-800 font-inter">
              Automatically notify the chain of command required to approve
              purchase requests
            </li>
            <li className="leading-6 text-base text-gray-800 font-inter">
              Skip manual data entry and easily convert received items into
              asset cards
            </li>
          </ul>
        </div>
        <div className="lg:mt-0  mt-12">
          <img src={purchaseOrder} alt="purchaseOrder" className="w-[39rem]" />
        </div>
      </div>
    ),
    "Reports & Analytics": (
      <div className="flex md:justify-between lg:flex-nowrap flex-wrap items-center md:mt-14">
        <div className="">
          <h3 className="lg:text-3xl text-lgfont-medium text-gray-900 font-inter">
            Reports & Analytics
          </h3>
          <ul className="lg:mt-6 mt-4 max-w-[29rem] w-full list-disc  pl-4 lg:pl-4 font-inter">
            <li className="leading-6 text-base text-gray-800 font-inter">
              Improve forecasting efficiency with our compliance aligned reports
            </li>
            <li className="leading-6 text-base text-gray-800 font-inter">
              For K-12, use our financial forecast tool to leverage salary
              schedule data accurately predicting future liabilities
            </li>
            <li className="leading-6 text-base text-gray-800 font-inter">
              Visualize company inventories in new innovative ways such as
              warranty deadlines, depreciation, and fixed asset reports
            </li>
          </ul>
        </div>
        <div className="lg:mt-0  mt-12">
          <img
            src={reportAnalytics}
            alt="reportAnalytics"
            className="w-[39rem]"
          />
        </div>
      </div>
    ),
  };

  // Define animation variants
  const contentVariants = {
    hidden: { opacity: 0, y: -10, transition: { duration: 0.9 } },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
    exit: { opacity: 0, y: 10, transition: { duration: 0.5 } },
  };

  useEffect(() => {
    const hash = window.location.hash.replace("#", "");
    setHashValue(hash);

    const handleHashChange = () => {
      const updatedHash = window.location.hash.replace("#", "");
      setHashValue(updatedHash);
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  useEffect(() => {
    if (hashValue) {
      const filterTabName =
        tabs.find((tab) => tab.id === hashValue)?.name || "";
      if (filterTabName) {
        setCurrentTab(filterTabName);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashValue]);

  return (
    <div className="container mx-auto md:mt-48" id="features">
      <h1
        className="md:text-4xl text-lg leading-7 md:leading-9 font-medium  text-gray-900 lg:mt-0  mt-12 mb-6"
        id="management"
      >
        Features to Transform Your Workflow
      </h1>

      <div className="">
        <div className="lg:hidden mb-4">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            defaultValue={currentTab}
            onChange={(e) => setCurrentTab(e.target.value as TabName)}
            className="block w-full  py-4 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden lg:block">
          <div className="border-b border-[#F5F6FA]">
            <nav aria-label="Tabs" className="gap-3 flex">
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  id={tab.id}
                  onClick={() => setCurrentTab(tab.name)}
                  aria-current={tab.current ? "page" : undefined}
                  className={classNames(
                    tab.current
                      ? "border-green-500 text-green-600 font-medium rounded-t-lg rounded-bl-none rounded-br-none"
                      : "border-transparent text-gray-500 text-base leading-5 font-light rounded-lg",
                    "whitespace-nowrap border-b-2 text-base focus:outline-none p-3 font-inter hover:bg-[#EFF0F3] "
                  )}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
        <div className=" ">
          {/* Wrap the content with motion.div and apply variants */}
          <motion.div
            key={currentTab}
            initial="hidden"
            animate="visible"
            variants={contentVariants}
          >
            {content[currentTab]}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
